@font-face {
  font-family: "LINESeedSansKR-Bold";
  src: url("./fonts/LINESeedKR-Bd.woff2") format("woff2");
}

@font-face {
  font-family: "LINESeedSansKR-Regular";
  src: url("./fonts/LINESeedKR-Rg.woff2") format("woff2");
}

@font-face {
  font-family: "LINESeedSansKR-Thin";
  src: url("./fonts/LINESeedKR-Th.woff2") format("woff2");
}

@font-face {
  font-family: "DM Sans";
  src: url("./fonts/DMSans-Regular.ttf") format("truetype");
}

html {
  scroll-snap-type: y proximity;
}

body {
  margin: 0;
  font-family: "LINESeedSansKR-Regular", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
